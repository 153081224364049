@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;700&display=swap');

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 30px;
  background-color: #398efb;
  font-family: 'Poppins', sans-serif;
  position: relative;
  z-index: 1000;
}

.navbar-brand {
  display: flex;
  align-items: center;
}

.logo {
  height: 40px;
}

.navbar-menu {
  display: flex;
  gap: 30px;
  justify-content: flex-end;
  flex: 1;
  margin-right: 40px;
  z-index: 1100;
}

.navbar-menu a {
  color: white;
  text-decoration: none;
  font-family: 'Poppins', sans-serif;
  position: relative;
}

.navbar-menu a:after {
  content: '';
  position: absolute;
  width: 0;
  height: 2px;
  bottom: 0;
  left: 0;
  background-color: white;
  transition: width 0.3s ease;
}

.navbar-menu a:hover:after {
  width: 100%;
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
  z-index: 1200;
}

.hamburger .line {
  width: 25px;
  height: 3px;
  background-color: white;
  margin: 4px 0;
  transition: transform 0.3s ease;
}

.hamburger[aria-expanded="true"] .line:nth-child(1) {
  transform: rotate(45deg) translateY(8px) translateX(7px);
}

.hamburger[aria-expanded="true"] .line:nth-child(2) {
  opacity: 0;
}

.hamburger[aria-expanded="true"] .line:nth-child(3) {
  transform: rotate(-45deg) translateY(-8px) translateX(7px);
}

@media (max-width: 768px) {
  .navbar-menu {
    display: none;
    flex-direction: column;
    width: 100%;
    background-color: #398efb;
    position: absolute;
    top: 60px;
    left: 0;
    gap: 20px;
    padding: 20px;
    margin: 0;
  }

  .navbar-menu.is-active {
    display: flex;
  }

  .navbar-menu.is-active a {
    display: block;
    text-align: left;
    padding: 10px 0;
    font-size: 1rem;
  }

  .hamburger {
    display: flex;
  }

  .social-media {
    margin-left: 0;
    padding: 10px;
  }

  .navbar-menu a:after {
    display: none;
  }

  .navbar-menu a:hover {
    background-color: rgba(255, 255, 255, 0.1);
  }
}

.social-media {
  display: flex;
  gap: 15px;
  align-items: center;
  margin-left: 20px;
  height: 100%;
}

.social-media img {
  max-width: 24px;
  height: 24px;
  cursor: pointer;
  transition: transform 0.2s ease;
  vertical-align: middle;
}

.social-media img:hover {
  transform: scale(1.1);
}

.navbar-brand .logo {
  max-height: 50px;
}