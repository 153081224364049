@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&display=swap');

.countdown {
  margin-top: 20px; 
  font-family: 'Roboto', sans-serif;
  font-weight: 700;
}

.countdown .content {
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: center;
	margin: auto;
	gap: 20px;
}
.countdown h2 {
	margin-bottom: 20px;
	font-size: 50px;
}

.countdown .box {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	height: 100px;
	max-width: 100px;
}

.countdown .value {
	font-size: 30px;
	font-weight: 600;
	background-color: rgba(255, 255, 255, 0.876);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	border-radius: 8px;
	width: 80px;
	height: 80px;
	position: relative;
}

@media screen and (min-width: 550px) {
	.countdown .content {
		gap: 30px;
	}
	.countdown .value {
		font-size: 40px;
		width: 100px;
		height: 100px;
	}
	.countdown .box {
		height: 150px;
		max-width: 150px;
	}
}

@media screen and (min-width: 790px) {
	.countdown .value {
		font-size: 50px;
		width: 120px;
		height: 120px;
	}
	.countdown .box {
		max-width: 200px;
		height: 200px;
	}
}

.countdown .label {
	font-size: 16px;
	letter-spacing: 0px;
	margin-top: 5px;
    font-weight: 400;
	text-transform: uppercase;
}